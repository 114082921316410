@charset "UTF-8";
body {
  background: #121526;
  font-family: Arial, sans-serif;
  margin: 0;
  color: #fff; }

.article,
.banner,
footer {
  max-width: 1360px;
  margin: 0 auto; }

header {
  background: #132648;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  margin: auto;
  max-width: 1360px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999; }

.sign {
  text-decoration: none;
  padding: 10px 26px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(180deg, #33c600, #206400);
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: #eef1f6; }
  .sign:hover {
    background-image: linear-gradient(180deg, #1dce44, #007f1c);
    color: #eef1f6; }

.sign-in {
  background: unset;
  border: 2px solid #35bd52;
  color: #9ba7ca;
  border-radius: 20px; }
  .sign-in:hover {
    background: unset;
    border-color: #67ce7d;
    box-shadow: none;
    color: #eef1f6; }

.primary {
  display: flex;
  margin-top: 20px;
  margin-bottom: 14px;
  text-align: center;
  justify-content: center;
  padding: 20px;
  border-radius: 40px;
  font-size: 16px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; }

.container {
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  box-sizing: border-box; }

p {
  color: #E5F1FF;
  line-height: 22px; }
  p strong {
    color: #fff;
    font-weight: bold; }

ul,
ol {
  list-style-position: outside;
  padding-inline-start: 20px; }

ul li,
ol li {
  color: #E5F1FF;
  line-height: 22px;
  margin-bottom: 10px; }

.main {
  margin-top: 70px;
  text-align: center;
  overflow: hidden;
  background: #0b1124;
  padding: 0 20px;
  border-bottom: 3px solid #45495d; }
  .main img {
    max-width: 100%;
    height: auto;
    max-height: 232px; }
  .main .welcome-text {
    background: linear-gradient(98.32deg, #815e00 -2%, #ffe9af 54.7%, #815e00 107.96%);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    color: #dbc17d;
    overflow: hidden; }
  .main p {
    margin: 0; }
  .main p.top {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px; }
  .main p.bottom {
    color: #dbc17d;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px; }

.container.slots {
  padding: 10px 0;
  background: #132648; }

.demo-games {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .demo-games .slot {
    border-radius: 6px;
    overflow: hidden;
    margin: 6px;
    background: #22375d;
    box-shadow: 1px 2px 3px #191a29;
    position: relative;
    transition: all 0.1s ease;
    max-width: 250px;
    text-decoration: none;
    border: 1px solid transparent; }
    .demo-games .slot:hover {
      border: 1px solid #41b0fe; }
    .demo-games .slot .image-box {
      position: relative; }
      .demo-games .slot .image-box img {
        object-fit: cover;
        width: 100%;
        margin: 0; }
      .demo-games .slot .image-box .buttons {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 50;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        opacity: 0; }
        .demo-games .slot .image-box .buttons .play,
        .demo-games .slot .image-box .buttons .demo {
          background-color: #55a635;
          box-shadow: 0 3px 0 0 #3c7028, 0 4px 4px 0 #000;
          transition: all .3s ease;
          font-size: 14px;
          height: 30px;
          padding: 0 30px;
          display: flex;
          align-items: center;
          border-radius: 30px;
          cursor: pointer;
          text-transform: uppercase;
          margin-right: 10px;
          font-weight: 700;
          letter-spacing: 1.6px;
          text-decoration: none;
          color: #fff;
          margin-top: 20px; }
          .demo-games .slot .image-box .buttons .play:hover,
          .demo-games .slot .image-box .buttons .demo:hover {
            background-color: #70d548; }
        .demo-games .slot .image-box .buttons .demo {
          box-shadow: unset;
          background-color: grey;
          margin-bottom: 20px;
          margin-top: 0;
          font-size: 12px;
          letter-spacing: normal; }
          .demo-games .slot .image-box .buttons .demo:hover {
            background: #000; }
    .demo-games .slot p {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 12px;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
      min-height: 20px;
      align-items: center; }
  .demo-games .slot:hover .buttons {
    opacity: 1;
    transition: all .3s ease; }

.article {
  background: #132648;
  margin-top: 0;
  font-size: 16px;
  padding-bottom: 20px; }
  .article img {
    max-width: 100%;
    height: auto;
    max-height: 500px; }
  .article h1 {
    padding-top: 20px;
    margin-top: 0; }

.ov-v {
  overflow: visible; }

.info.ov-v {
  margin-top: 60px;
  border-top-left-radius: 6px; }

.note {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px; }
  .note p span {
    color: #00abff; }

a {
  color: #00ABFF; }

h2 {
  font-size: 20px;
  line-height: 24px; }

.table-of-conetnt {
  background: #00ABFF; }

.scroll {
  overflow-x: auto;
  border-radius: 12px;
  background: linear-gradient(314deg, #0A78AE -13.43%, #0E2452 64.63%); }

table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); }

th,
td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  line-height: 1.3; }

h2 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 2px solid #1b2f55;
  padding-bottom: 10px; }

.feature-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.feature-item {
  flex: 1 0 34%;
  margin: 15px;
  padding: 20px;
  background-color: #1a2f55;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); }

strong {
  font-weight: bold; }

.faq details,
.table-of-content details {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #38579B;
  padding-bottom: 20px; }
  .faq details:last-child,
  .table-of-content details:last-child {
    border-bottom: none; }

.faq details[open],
.table-of-content details[open] {
  z-index: 1; }

.faq summary,
.table-of-content summary {
  padding: 30px 50px 0 0;
  cursor: pointer;
  font-size: 16px;
  list-style: none;
  font-weight: bold;
  color: #fff; }

.faq summary::-webkit-details-marker,
.table-of-content summary::-webkit-details-marker {
  display: none; }

.faq summary:after,
.table-of-content summary:after {
  content: url("../img/arrow.svg");
  transform: rotate(180deg);
  transform-origin: center center;
  transition: transform ease-in-out 100ms;
  position: absolute;
  right: 0;
  width: 38px;
  height: 42px;
  top: 30px; }

.faq summary:focus,
.table-of-content summary:focus {
  outline: none; }

.faq details[open] summary:after,
.table-of-content details[open] summary:after {
  transform: rotate(0deg); }

.table-of-content {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #38579B;
  border-bottom: 1px solid #38579B; }
  .table-of-content ul li {
    list-style-type: none;
    position: relative;
    padding-left: 15px; }
    .table-of-content ul li:before {
      content: '💎';
      position: absolute;
      left: -20px;
      width: 24px;
      height: 24px; }
  .table-of-content a {
    text-decoration: none; }
  .table-of-content summary {
    font-size: 18px; }
  .table-of-content summary:after {
    top: 15px; }

footer {
  margin-top: 40px;
  padding: 15px; }
  footer .f-header {
    display: flex;
    justify-content: space-between; }
    footer .f-header .age {
      font-weight: bold; }

@media (min-width: 0px) and (max-width: 320px) {
  .sign {
    padding: 12px 10px; } }

@media (min-width: 321px) and (max-width: 364px) {
  .sign {
    padding: 12px 16px; } }

@media (min-width: 0px) and (max-width: 768px) {
  .demo-games .slot {
    max-width: 45%; } }

@media (min-width: 769px) {
  .faq summary:after {
    top: 10px; } }
